import {Component, computed, inject, Signal} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

import {toSignal} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-login-layout',
    templateUrl: 'login-layout.component.html',
    styleUrls: ['login-layout.component.scss'],
    standalone: false,
})
export class LoginLayoutComponent {
    private router = inject(Router);
    private routerUpdate: Signal<NavigationEnd> = toSignal(this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)));

    protected isAccountRequest = computed(() => {
        return this.routerUpdate().url.includes('account-request');
    });

    protected isAccountReactivation = computed(() => {
        return this.routerUpdate().url.includes('account-reactivation');
    });

    protected showOutageLinks = computed(() => {
        return this.routerUpdate().url.endsWith('login');
    });

    constructor() {}
}
