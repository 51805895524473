import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'eaglei-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
    standalone: false,
})
export class TermsOfUseComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
