<mat-card>
    <mat-card-title class="header-bar">
        <span>MAPSTER Data Options</span>
        <button mat-icon-button (click)="close()">
            <fa-icon icon="times" (click)="close()"></fa-icon>
        </button>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content #content>
        @if (properties) {
        <mat-list>
            <mat-list-item>
                <div matListItemTitle>File Name:</div>
                <div matListItemLine>{{ properties.fileName }}</div>
            </mat-list-item>
            <mat-list-item>
                <div matListItemTitle>State and County:</div>
                <div matListItemLine>{{ properties.county }}, {{ properties.state }}</div>
            </mat-list-item>
            <mat-list-item>
                <div matListItemTitle>Upload Date:</div>
                <div matListItemLine>{{ properties.uploadDate | momentDate }}</div>
            </mat-list-item>
        </mat-list>
        }
    </mat-card-content>
    <mat-card-actions align="end">
        @if(properties.isTiled) {
        <button mat-flat-button color="primary" (click)="zoomToImage()">
            <fa-icon icon="magnifying-glass-plus"></fa-icon> Zoom To Image
        </button>
        } @else {
        <button mat-flat-button color="primary" (click)="tileDataset()"><fa-icon icon="puzzle-piece"></fa-icon> Tile Dataset</button>
        }
    </mat-card-actions>
</mat-card>
