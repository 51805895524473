import {Component} from '@angular/core';
import {LockTimeService} from '../../services/lock-time.service';

@Component({
    selector: 'eaglei-lock-time-banner',
    templateUrl: './lock-time-banner.component.html',
    styleUrls: ['./lock-time-banner.component.scss'],
    standalone: false,
})
export class LockTimeBannerComponent {
    public lockedTime$ = LockTimeService.currentApplicationTime;
    constructor() {}
}
