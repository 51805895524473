@if (isAccountRequest()) {
<router-outlet></router-outlet>
} @else {
<div class="action-section">
    <h1>Welcome to EAGLE-I&trade;</h1>

    @if (!isAccountReactivation()) {
    <p class="welcome-text">
        EAGLE-I™, an interactive geographic information system (GIS) that allows users to view and map the nation's energy infrastructure
        and obtain near real-time informational updates concerning the electric, petroleum and natural gas sectors within one visualization
        platform.
    </p>
    } @if (showOutageLinks()) {
    <div class="historical-data">
        <span>Need historical electrical outage data?</span>
        <ul>
            <li>
                A catalog of data from 2014 to present can be found on this
                <a target="_blank" href="https://figshare.com/s/417a4f147cf1357a5391">Figshare website</a>
            </li>
            <li>
                Related metadata and description information from 2014 to 2022 for United States counties, available in this
                <a
                    target="_blank"
                    href="https://link.springer.com/article/10.1038/s41597-024-03095-5?utm_source=rct_congratemailt&utm_medium=email&utm_campaign=oa_20240305&utm_content=10.1038/s41597-024-03095-5"
                    >Springer Nature article</a
                >.
            </li>
        </ul>
    </div>
    }

    <router-outlet></router-outlet>
</div>

<div class="warning-section">
    <div class="warning-text">
        <img src="dist/browser/images/logos/doe-logo.png" alt="doe logo" />

        <img class="wide" src="dist/browser/images/logos/ceser_logo.png" alt="ceser logo" />

        <p>
            Portions of this system are designated Official Use Only (OUO). Accounts and access are restricted to Federal agencies, Federal
            agency support contractors, and state emergency energy assurance coordinators.
        </p>

        <p>
            <b>NOTICE TO USERS:</b> This computer is a Federal computer system and is the property of the United States Government. It is
            for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy. Any or all uses
            of this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed
            to authorized site, Department of Energy, and law enforcement personnel, as well as authorized officials of other agencies, both
            domestic and foreign. By using this system, the user consents to such interception, monitoring, recording, copying, auditing,
            inspection, and disclosure at the discretion of authorized site or Department of Energy personnel. Unauthorized or improper use
            of this system may result in administrative disciplinary action and civil and criminal penalties. By continuing to use this
            system you indicate your awareness of and consent to the terms and conditions of use of the Warning Banner and EAGLE-I™ Terms of
            Use. <b>LOG OFF IMMEDIATELY</b> if you do not agree to these terms and conditions.
        </p>
    </div>
</div>
}
