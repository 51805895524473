import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MapsterPackageOptions} from './models';
import {Observable, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MapsterService {
    private http = inject(HttpClient);
    constructor() {}

    public getPackageOptions(id: string): Observable<MapsterPackageOptions> {
        return this.http.get<MapsterPackageOptions>(`api/mapster/package-options/${id}`);
    }

    public tileDataSet(id: string): Observable<unknown> {
        return this.http.get(`api/mapster/tile-data/${id}`);
    }
}
