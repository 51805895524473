import {Component, inject, OnDestroy, signal, WritableSignal} from '@angular/core';
import {BasePopover} from '../base-popover';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {switchMap} from 'rxjs';
import {MatCardModule} from '@angular/material/card';
import {KeyValuePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../../../../../shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {Mapster} from '../../classes/mapster';
import {MapsterPackageOptions, MapsterPackageOptionsMap} from '../../../../integrations/mapster/models';
import {FormControl, Validators} from '@angular/forms';
import {MapsterService} from '../../../../integrations/mapster/mapster.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LeafletFeature} from '../../classes/leaflet-feature';
import {MapService} from '../../../map/services/map.service';
import L from 'leaflet';

export const DEFAULT_MAPSTER_OPTIONS: MapsterPackageOptions = {
    image: false,
    imageMetadata: false,
    video: false,
    videoMetadata: false,
    lidar: false,
    pdal: false,
    thumbnail: false,
    uuid: '',
};

@Component({
    selector: 'eaglei-mapster-popover',
    templateUrl: './mapster-popover.component.html',
    styleUrls: ['./mapster-popover.component.scss'],
    imports: [
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatTabsModule,
        SharedModule,
        FontAwesomeModule,
        KeyValuePipe,
    ],
    standalone: true,
})
export class MapsterPopoverComponent extends BasePopover<LeafletFeature<Mapster>> implements OnDestroy {
    private mapsterService = inject(MapsterService);

    public loading = signal(true);
    public dataOptions: WritableSignal<MapsterPackageOptions> = signal(DEFAULT_MAPSTER_OPTIONS);
    public optionMap = MapsterPackageOptionsMap;
    public optionsForm = new FormControl<string[]>(null, Validators.required);
    public properties!: Mapster;
    private snackBar = inject(MatSnackBar);

    constructor() {
        super();
        this.data$
            .pipe(
                takeUntilDestroyed(),
                switchMap((data: LeafletFeature<Mapster>) => {
                    this.properties = data.properties;
                    return this.mapsterService.getPackageOptions(this.properties.mapsterId);
                })
            )
            .subscribe((options: MapsterPackageOptions) => {
                this.dataOptions.set(options);
            });
    }

    // Abstract from the super class
    afterInit() {}

    // Abstract from the super class
    onInit() {}

    // Abstract from the super class, We should look at deprecating in favor of using data$
    updateData() {}

    public tileDataset() {
        this.mapsterService.tileDataSet(this.properties.mapsterId).subscribe({
            next: () => {
                this.snackBar.open('An email will be sent when the dataset is tiled', 'X', {duration: 5_000});
                this.close();
            },
            error: (e) => {
                this.snackBar.open('There was an issue tiling the data', 'X', {duration: 5_000});
            },
        });
    }

    zoomToImage() {
        const bounds = L.geoJSON([this.data as any]);
        MapService.mapRef.flyToBounds(bounds.getBounds());
    }
}
